@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

@font-face {
  font-family: 'OpenSans';
  src: url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,400;8..144,500;8..144,600&display=swap');
}
@font-face {
  font-family: "Nova-Bold";
  src: url('./asset/ProximaNova/Proxima-Nova-Bold.otf');
}
@font-face {
  font-family: "Proxima Nova";
  src: url('./asset/ProximaNova/Proxima-Nova-Light.otf');
}
@font-face {
  font-family: "Nova-SemiBold";
  src: url('./asset/ProximaNova/Proxima-Nova-Sbold.otf');
}
@font-face {
  font-family: "TTBerlinerins-Grotesk";
  src: url('./asset/TTBerlinerins/TTBerlinerins-Grotesk.otf');
}

/* @layer base {
  h1 {
    @apply text-4xl;
    @apply font-bold;
  }
  h2 {
    @apply text-3xl;
    @apply font-bold;
  }
  h3 {
    @apply text-2xl;
    @apply font-bold;
  }
  h4 {
    @apply text-xl;
    @apply font-semibold;
  }
  h5 {
    @apply text-lg;
    @apply font-semibold;
  }
  h6 {
    @apply text-sm;
    @apply font-semibold;
  }
} */

body{
  background-color: #fff;
  font-family: 'Quicksand', sans-serif;
}
body, #root{
  height: 100vh;
}
.revuelo-logo{
  width: 150px;
  /* margin-bottom: 1rem; */
}
header .revuelo-logo{
  width: 20%;
}
.revuelo-text-name{
  font-family: 'TTBerlinerins-Grotesk', sans-serif;
  font-size: 2rem;
}
.img-banner{
  background-blend-mode: multiply;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  background-color: rgb(55 65 81/0.8);
  object-fit: contain;
}
.revuelo-text-name span{
  font-size: 1rem;
}
.wrapper-landing{
  background: rgb(0,0,0,0.5);
  height: 100vh;
  width: 100%;
}
.landing-background{
  position: absolute;
  z-index: -1;
}
.sidebar-wrapper{
  z-index: 1;
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  color: #fff;
  background: rgba(0,0,0,0.5);
}
.image-landing-wrapper{
  position: fixed;
  z-index: 0;
  top: 0;
}
.child-link-wrapper a, .copyright-landing{
  font-family: "Proxima Nova", sans-serif;
}
.copyright-landing{
  font-weight: lighter;
  font-size: 0.9em;
}
.child-link-wrapper{
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(4, minmax(200px, 1fr))
}
.pagination {
  padding: 2rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  cursor: pointer;
}
.pagination a {
  padding: 10px;
  border-radius: 0.375rem;
  border: 1px solid #1f2937;
  color: #1f2937;
  margin-right: 0.5rem;
  min-height: 38px;
}

.pagination__link {
  font-weight: bold;
  display: block;
  min-height: 38px;
}
.pagination__link--active a {
  color: #fff;
  background: #1f2937;
}

.pagination__link--disabled a {
  color: rgb(198, 197, 202);
  border: 1px solid rgb(198, 197, 202);
  opacity: 50%;
  color: #bfbfbf;
  pointer-events: none;
  cursor: default;
  display: block;
}

@media (max-width: 480px){
  .revuelo-logo{
    width: 120px;
  }
  .wrapper-banner{
    /* height: inherit; */
  }
}
.custom-shape-divider-bottom-1663263246 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1663263246 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 41px;
}


/* Wave Footer Separator */
.custom-shape-divider-top-1663265067 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top-1663265067 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 41px;
}

.custom-shape-divider-top-1663265067 .shape-fill, 
.custom-shape-divider-bottom-1663263246 .shape-fill {
  fill: #fff;
}
.custom-shape-divider-top-1663265067 .shape-grey, 
.custom-shape-divider-bottom-1663263246 .shape-grey {
  fill: #f0f0f0;
}
.image-gallery-thumbnail.active, .image-gallery-thumbnail:focus{
  border: 4px solid #1f2937;
}
.min-card-content{
  min-height: 210px;
}
.rdw-editor-main{
  border-color: rgb(209 213 219 / var(--tw-border-opacity)) !important;
}
.toolbar{
  /* background-color: #f2f2f2 !important; */
  border-radius: 0.5rem !important;
  border: 1px solid !important;
  border-color: rgb(209 213 219 / var(--tw-border-opacity)) !important;
}
.card-body{
  border: 1px solid #cccccc !important;
  border-radius: 0.5rem !important;
  padding: 0 1rem;
  min-height: 200px;
  background-color: #fff;
}
.card-body:hover{
  cursor: text;
}
.public-DraftStyleDefault-ltr{
  /* margin: 0 !important; */
  line-height: 1.25rem;
  /* line-height: 10px; */
}
.content-body-post p{
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
.rdw-list-dropdown, .rdw-text-align-dropdown{
  z-index: 3 !important;
}

.DraftEditor-editorContainer .public-DraftEditor-content h1{
  font-size: 2em!important;
  font-weight: bolder!important;
}

.DraftEditor-editorContainer .public-DraftEditor-content h2{
font-weight: bolder!important;
font-size: 1.5em!important;
}

.DraftEditor-editorContainer .public-DraftEditor-content h3{
font-weight: bolder!important;
font-size: 1.7em!important;

}

.DraftEditor-editorContainer .public-DraftEditor-content h4{
font-size: 1em!important;
font-weight: bolder!important;
}

.DraftEditor-editorContainer .public-DraftEditor-content h5{
font-size: .83em!important;
font-weight: bolder!important;
}

.DraftEditor-editorContainer .public-DraftEditor-content h6{
font-size: .67em!important;
font-weight: bolder!important;
}
.view-post iframe {
  height: 100vh;
  width: 100%;
}
.image-gallery-swipe {
  position: relative;
  z-index: 0;
}
.star-empty:hover {
  fill: #f7cb68;
}
/* .content-body-post p{
  margin-bottom: 0.5rem;
} */
.public-DraftStyleDefault-block {
  margin: 0;
}
.content-body-post p {
  margin: 0;
}
.rdw-editor-main{
  padding: 1rem;
}
@media(max-width: 480px){
  .custom-shape-divider-bottom-1663263246 {
    bottom: -1px;
  }
  .view-post a{
    display: inline-flex;
    width: fit-content;
  }
  .view-post iframe{
    height: auto;
  }
}